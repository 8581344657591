import axios from "axios";
import { ADMIN_BASE_URL } from "./api";

const adminApiClient = axios.create({
  baseURL: ADMIN_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "*/*",
    "Content-Type": "*/*",
  },
});

function initializeToken() {
  setAccessToken();
}

async function setAccessToken() {
  let token = "";
  adminApiClient.defaults.headers.common["Authorization"] = "";
  delete adminApiClient.defaults.headers.common["Authorization"];

  if (localStorage.getItem("persist:pgMerchant") !== null) {
    if ("auth" in JSON.parse(localStorage.getItem("persist:pgMerchant"))) {
      let data = JSON.parse(localStorage.getItem("persist:pgMerchant"));
      token = JSON.parse(data?.auth).token;
    } else {
      token = null;
    }
  } else {
    token = null;
  }

  if (token) {
    adminApiClient.defaults.headers.common["Authorization"] = `Token ${token}`;
  }
}

initializeToken();

adminApiClient.interceptors.response.use((response) => {
  // console.log(response);
  return response;
});

export { initializeToken };
export default adminApiClient;
