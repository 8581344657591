import PaymentsApiClient from "../../api/PaymentsApiClient";
import {
  CLEAR_PAYMENTS_STATE,
  CREATE_PAYMENT,
  SET_PAYMENTS,
  SET_PAYMENT_REQUEST,
  SET_SELECTED_PAYMENT,
  TOGGLE_FETCHING_PAYMENTS,
  TOGGLE_FETCH_PAYMENT_REQUEST,
} from "./paymentsActionTypes";

export const clearPaymentsState = (data) => {
  return {
    type: CLEAR_PAYMENTS_STATE,
  };
};

export const setPayments = (data) => {
  return {
    type: SET_PAYMENTS,
    payload: data,
  };
};

export const toggleFetchingPayments = (status) => {
  return {
    type: TOGGLE_FETCHING_PAYMENTS,
    payload: status,
  };
};

export const createPaymnet = (data) => {
  return {
    type: CREATE_PAYMENT,
    payload: data,
  };
};

export const setSelectedPayment = (data) => {
  return {
    type: SET_SELECTED_PAYMENT,
    payload: data,
  };
};

export const toggleFetchPayentRequest = (status) => {
  return {
    type: TOGGLE_FETCH_PAYMENT_REQUEST,
    payload: status,
  };
};

export const setPaymentRequest = (data) => {
  return {
    type: SET_PAYMENT_REQUEST,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchPaymentRequestsAction(limit, page) {
  return function action(dispatch) {
    dispatch(clearPaymentsState);
    if (page === 1) dispatch(toggleFetchPayentRequest(true));

    return PaymentsApiClient.fetchPaymentsRequest(limit, page)
      .then((response) => {
        const { data } = response;
        dispatch(setPaymentRequest(data));
        dispatch(toggleFetchPayentRequest(false));
      })
      .catch((error) => {
        dispatch(toggleFetchPayentRequest(false));
      });
  };
}

export function fetchPaymentsAction(limit, page) {
  return function action(dispatch) {
    dispatch(clearPaymentsState);
    if (page === 1) dispatch(toggleFetchingPayments(true));

    return PaymentsApiClient.fetchPayments(limit, page)
      .then((response) => {
        const { data } = response;
        dispatch(setPayments(data));
        dispatch(toggleFetchingPayments(false));
      })
      .catch((error) => {
        dispatch(toggleFetchingPayments(false));
      });
  };
}

export function createPaymentAction(data) {
  return function action(dispatch) {
    return PaymentsApiClient.paymentRequest(data)
      .then((response) => {
        if (response.status === 200) {
          // dispatch(createPaymnet(response.data));
          return { status: true, data: response.data.message };
        }
      })
      .catch((error) => {
        return { status: false, data: error.response };
      });
  };
}

export function setSelectedPaymentAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedPayment(data));
  };
}
