import DocumentsClient from "../../api/DocumentsClient";
import {
  SET_DOCUMENTS,
  SET_SELECTED_ACCOUNT,
  TOGGLE_FETCHING_DOCUMENTS,
  UPLOAD_DOCUMENTS,
} from "./documentsActionTypes";

export const toggleFetchingDocuments = (status) => {
  return {
    type: TOGGLE_FETCHING_DOCUMENTS,
    payload: status,
  };
};

export const setDocuments = (data) => {
  return {
    type: SET_DOCUMENTS,
    payload: data,
  };
};

export const uploadDocuments = (data) => {
  return {
    type: UPLOAD_DOCUMENTS,
    payload: data,
  };
};

export const setSelectedAccount = (data) => {
  return {
    type: SET_SELECTED_ACCOUNT,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchingDocumentsAction() {
  return function action(dispatch) {
    dispatch(toggleFetchingDocuments(true));

    return DocumentsClient.fetchDocuments()
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(setDocuments(response.data.data));
          dispatch(toggleFetchingDocuments(false));
        }
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch(toggleFetchingDocuments(false));
      });
  };
}
export function uploadDocumentsAction(data) {
  return function action(dispatch) {

    return DocumentsClient.uploadDocument(data)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          dispatch(uploadDocuments(response.data.data));
        }
        return true;
      })
      .catch((error) => {
        // console.log(error.response);
        return false;
      });
  };
}

export function setSelectedAccountAction(data) {
  return function action(dispatch) {
    dispatch(setSelectedAccount(data));
  };
}
