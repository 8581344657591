import TransactionsClient from "../../api/TransactionsClient";
import {
  INITIALIZE_TRANSACTIONS_STATE,
  SET_MONEY_TRANSFERS,
  SET_MONEY_TRANSFER_RECORD,
  SET_TRANSACTIONS,
  SET_TRANSACTION_ERROR_MESSAGE,
  TOGGLE_FETCHING_MONEY_TRANSFERS,
  TOGGLE_FETCHING_TRANSACTIONS,
  TOGGLE_FETCHING_TRANSACTIONS_REPORT,
} from "./actionTypes";
// set the actions
export const initializeTransactionsState = () => {
  return {
    type: INITIALIZE_TRANSACTIONS_STATE,
  };
};

export const toggleFetchingTransactions = (status) => {
  return {
    type: TOGGLE_FETCHING_TRANSACTIONS,
    payload: status,
  };
};

export const toggleFetchingMoneyTransfers = (status) => {
  return {
    type: TOGGLE_FETCHING_MONEY_TRANSFERS,
    payload: status,
  };
};

export const toggleFetchingTransactionsReport = (status) => {
  return {
    type: TOGGLE_FETCHING_TRANSACTIONS_REPORT,
    payload: status,
  };
};

export const setTransactions = (data) => {
  return {
    type: SET_TRANSACTIONS,
    payload: data,
  };
};

export const setMoneyTransfers = (data) => {
  return {
    type: SET_MONEY_TRANSFERS,
    payload: data,
  };
};

export const setTransactionErrorMessage = (error) => {
  return {
    type: SET_TRANSACTION_ERROR_MESSAGE,
    payload: error,
  };
};

export const setMoneyTransferRecord = (data) => {
  return {
    type: SET_MONEY_TRANSFER_RECORD,
    payload: data,
  };
};

// ////////////////// DISPATCH ACTIONS /////////////

export function fetchTransactionsAction(limit, page) {
  return function action(dispatch) {
    if (page === 1) dispatch(toggleFetchingTransactions(true));

    return TransactionsClient.fetchTransactions(limit, page)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setTransactions(response.data.data));
          dispatch(toggleFetchingTransactions(false));
        }
      })
      .catch((error) => {
        dispatch(toggleFetchingTransactions(false));
      });
  };
}

export function fetchMoneyTransfersAction(limit, page) {
  return function action(dispatch) {
    if (page === 1) dispatch(toggleFetchingMoneyTransfers(true));

    return TransactionsClient.fetchWithdrawals(limit, page)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setMoneyTransfers(response.data));
          dispatch(toggleFetchingMoneyTransfers(false));
        }
      })
      .catch((error) => {
        dispatch(toggleFetchingMoneyTransfers(false));
      });
  };
}

export function transferToMpesaAction(data) {
  return function action(dispatch) {
    return TransactionsClient.transferToMpesa(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setMoneyTransferRecord(response.data.data));
          return true;
        }
      })
      .catch((error) => {
        // console.log(error.response);
        return false;
      });
  };
}

export function transferToPayGramAction(data) {
  return function action(dispatch) {
    return TransactionsClient.transferToPayGram(data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.status === 200) {
          dispatch(setMoneyTransferRecord(response.data.data));
        }
        return true;
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  };
}

export function exportTransactionsReport(format) {
  return function action(dispatch) {
    dispatch(toggleFetchingTransactionsReport(true));

    return TransactionsClient.exportTransactionsReport(format)
      .then((response) => {
        if (response.status === 200) {
          const resp = response.data;
          console.log(resp);
          dispatch(toggleFetchingTransactionsReport(false));
        }
        return true;
      })
      .catch((error) => {
        dispatch(toggleFetchingTransactionsReport(false));
        return false;
      });
  };
}
