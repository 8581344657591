// list all the menu items here
export const menuItems = [
  {
    id: 1,
    key: "dashboard",
    label: "Dashboard",
    path: "/home",
    icon: <i className="bx bx-home-alt"></i>,
  },
  {
    id: 2,
    key: "admin",
    label: "Administration",
    path: "/admin",
    icon: <i className="bx bxs-shield"></i>,
  },
  {
    id: 3,
    key: "payments",
    label: "Received Payments",
    path: "/payments",
    icon: <i className="bx bx-wallet-alt"></i>,
  },
  {
    id: 4,
    key: "transactions",
    label: "All transactions",
    path: "/transactions",
    icon: <i className="bx bx-spreadsheet"></i>,
  },
  {
    id: 5,
    key: "moneyTransfers",
    label: "Money Transfers",
    path: "/moneyTransfers",
    icon: <i className="bx bx-transfer"></i>,
  },
];

export const bottomMenu = [
  {
    key: "profile",
    label: "Profile",
    path: "/profile",
    icon: <i className="bx bx-user-circle"></i>,
  },
  {
    key: "notifications",
    label: "Notifications",
    path: "/notifications",
    icon: <i className="bx bx-bell"></i>,
  },
];
