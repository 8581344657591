export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";

export const REGENERATE_QR_CODE = "REGENERATE_QR_CODE"

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNING_UP = "SIGNING_UP";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const SET_USER_LOCATION = "SET_USER_LOCATION";
