import React from "react";
import { useSelector } from "react-redux";

function Footer() {
  const authstate = useSelector((state) => state?.auth);
  const links = [
    {
      title: "About Us",
      route: "about",
    },
    {
      title: "Terms & Conditions",
      route: "terms",
    },
    {
      title: "Privacy Policy",
      route: "privacy",
    },
  ];
  return (
    <div className="w-full items-center justify-center space-y-2 p-4">
      <div className=" w-full flex space-x-2 items-center justify-center">
        {links.map((item, index) => (
          <a
            key={index}
            href={item.route}
            target="_blank"
            rel="noopener noreferrer"
            className={`${
              !authstate?.isLoggedIn ? "text-black" : "text-gray-800"
            } text-xs sm:text-[10px] hover:text-white`}
          >
            <p>{item.title}</p>
          </a>
        ))}
      </div>
      <h1
        className={`${
          !authstate?.isLoggedIn ? "text-white" : "text-gray-600"
        } text-[11px]  text-center`}
      >
        &copy;{new Date().getFullYear()}, PayGram Inc.
      </h1>
    </div>
  );
}

export default Footer;
