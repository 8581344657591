/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  fetchPaymentsRequest(limit, page) {
    return apiClient.get(`/payment/request/view?rows=${limit}&page=${page}`);
  },
  fetchPayments(limit, page) {
    return apiClient.get(`/payment/view?rows=${limit}&page=${page}`);
  },
  paymentRequest(data) {
    return apiClient.post("/payment/request/init", data);
  },
};
