export const INITIALIZE_TRANSACTIONS_STATE = "INITIALIZE_TRANSACTIONS_STATE";

export const TOGGLE_FETCHING_TRANSACTIONS = "TOGGLE_FETCHING_TRANSACTIONS";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";

export const TOGGLE_FETCHING_MONEY_TRANSFERS =
  "TOGGLE_FETCHING_MONEY_TRANSFERS";
export const SET_MONEY_TRANSFERS = "SET_MONEY_TRANSFERS";
export const SET_MONEY_TRANSFER_RECORD = "SET_MONEY_TRANSFER_RECORD";

export const SET_TRANSACTION_SUCCESS_MESSAGE = "SET_TRANSACTION_SUCCESS_MESSAGE";
export const SET_TRANSACTION_ERROR_MESSAGE = "SET_TRANSACTION_ERROR_MESSAGE";

export const TOGGLE_FETCHING_TRANSACTIONS_REPORT =
  "TOGGLE_FETCHING_TRANSACTIONS_REPORT";