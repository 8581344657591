import axios from "axios";
import { BASE_URL } from "./api";

const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: "*/*",
    "Content-Type": "*/*",
  },
});

function initializeToken() {
  setAccessToken();
}

async function setAccessToken() {
  let token = "";
  apiClient.defaults.headers.common["Authorization"] = "";
  delete apiClient.defaults.headers.common["Authorization"];

  if (localStorage.getItem("persist:pgMerchant") !== null) {
    if ("auth" in JSON.parse(localStorage.getItem("persist:pgMerchant"))) {
      let data = JSON.parse(localStorage.getItem("persist:pgMerchant"));
      token = JSON.parse(data?.auth).token;
    } else {
      token = null;
    }
  } else {
    token = null;
  }

  if (token) {
    apiClient.defaults.headers.common["Authorization"] = `Token ${token}`;
  }
}

initializeToken();

apiClient.interceptors.response.use((response) => {
  // console.log(response);
  return response;
});

export { initializeToken };
export default apiClient;
