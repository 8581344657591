/* eslint-disable import/no-anonymous-default-export */
import adminApiClient from "../redux/AdminApiClient";
import apiClient from "../redux/ApiClient";

export default {
  regenerateQrCode() {
    return apiClient.post("document/regenerate/qr", {})
  },
  login(user) {
    return apiClient.post("/auth/login", user);
  },
  logout() {
    return adminApiClient.post("/auth/logout/");
  },
  signup(data) {
    return apiClient.post("/auth/store/create", data);
  },
};
