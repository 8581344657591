/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  //all documents
  fetchDocuments() {
    return apiClient.get("/documents/view");
  },
  uploadDocument(data) {
    return apiClient.post("/documents/upload", data);
  },
};
