/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  fetchWalletTransactions() {
    return apiClient.get("/wallet/retrieve");
  },
  fetchWallet() {
    return apiClient.get("/wallet/retrieve");
  },
};
