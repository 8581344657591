/* eslint-disable import/no-anonymous-default-export */
import apiClient from "../redux/ApiClient";

export default {
  //all transactions
  fetchTransactions(limit, page) {
    return apiClient.get(`/transactions/list?rows=${limit}&page=${page}`);
  },

  //all money transfers / withdrawals
  fetchWithdrawals(limit, page) {
    return apiClient.get(`/withdrawal/list?rows=${limit}&page=${page}`);
  },

  // export report
  exportTransactionsReport(format) {
    return apiClient.get(`/transaction/export?export_format=${format}`);
  },

  transferToMpesa(data) {
    return apiClient.post("/withdrawal/mpesa", data);
  },

  transferToPayGram(data) {
    return apiClient.post("/withdrawal/paygram", data);
  },
};
